import { useCallback, useMemo, useState } from "react";

import type { RequestErrorResult } from "~/utils/request-helpers.server";

import { useOrigin } from "./use-origin";

type FetcherState = "idle" | "submitting" | "loading";
type FetcherOptions<T> = {
  onError?: (response: RequestErrorResult) => any;
  // TODO: Use RequestSuccessResult later, because have to update all loader responses to using requestHelpers
  onSuccess?: (response: T) => any;
};

export function useRawFetcher<T>(options?: FetcherOptions<T>) {
  const origin = useOrigin();

  const [data, setData] = useState<T>();
  const [state, setState] = useState<FetcherState>("idle");
  const [error, setError] = useState<string>();

  // TODO: Remove routeId param
  // I cannot get the routeId from the path now
  // The routeId passed to the _data param has to match with the path
  const load = useCallback(
    async (path: string, routeId: string) => {
      try {
        setState("loading");
        setData(undefined);
        setError(undefined);

        const url = path.startsWith("https://") ? new URL(path) : new URL(path, origin);
        url.searchParams.set("_data", routeId);

        const response = await fetch(url.href, {
          method: "GET",
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const json = await response.json();
        setData(json);
        options?.onSuccess?.(json);
      } catch (error) {
        if (error instanceof Error) {
          // setError(error.message);
          options?.onError?.({ ok: false, data: undefined, error: error.message, errors: {} });
        } else {
          setError("Unknown error");
          options?.onError?.({ ok: false, data: undefined, error: "Unknown error", errors: {} });
        }
      } finally {
        setState("idle");
      }
    },
    [origin, options],
  );

  const fetcher = useMemo(
    () => ({
      load: (path: string, routeId: string) => void load(path, routeId),
      data,
      state,
    }),
    [data, state, load],
  );

  const fetcherState = useMemo(() => {
    const isSubmitting = state === "submitting";
    const isLoading = state === "loading";
    const isFetching = isSubmitting || isLoading;
    const isSuccess = state === "idle" && data;
    const isError = state === "idle" && error;

    return {
      error,
      isLoading,
      isFetching,
      isSubmitting,
      isSuccess,
      isError,
    };
  }, [state, data, error]);

  return [fetcher, fetcherState] as const;
}
